import vuePlyr from "vue-plyr/dist/vue-plyr.ssr"
import "vue-plyr/dist/vue-plyr.css"

// Interface for plugin injections
interface PluginsInjections {
  $vuePlyr: typeof vuePlyr
}

declare module "#app" {
  interface NuxtApp extends PluginsInjections {}
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties extends PluginsInjections {}
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("vue-plyr", vuePlyr) // Register the component globally
})
