<script setup lang="ts">
import { useThemeStore } from '~/stores/theme';

const themeStore = useThemeStore()

useHead({
  title: 'Media App'
})

onBeforeMount(async () => {
  // useChangeLocale('de')

  await $fetch("/api/core/app-settings/theme", {
    method: "GET"
  }).then((data) => {
    console.log(data)
    if (data) {
      themeStore.setDarkMode(data.darkMode, false)
    }
  }).catch((error) => { console.log(error) })

  // themeStore.setDarkMode(true, false)
  // themeStore.setPreset('Lara')
})


</script>

<template>
  <NuxtLoadingIndicator :height="2" :throttle="500" style="background-color: green !important;" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang='scss'>
@import '~/assets/layout/App.scss';
</style>
