export default defineNuxtRouteMiddleware(async (to, from) => {
  // if (import.meta.server && to.path === "/") var allowAccess = true
  if (import.meta.server) {
  }

  // Page not found
  if (!to.name) return navigateTo("/error/page-not-found")

  // always allow errors
  if (to.path.startsWith("/error")) return

  // always allow errors
  if (!to.path.startsWith("/core") && !to.path.startsWith("/auth")) return

  // request user information
  const user = useUser()
  const data = await useRequestFetch()("/api/auth/user")

  // if not logged in
  if (!data || !data.session || !data.user) {
    if (["/auth/login", "/auth/register", "/auth/registration-email-validation"].includes(to.path)) return
    return navigateTo("/auth/login")
  }

  user.value = data.user

  // allow login success page
  if (to.path === "/auth/login-success") return

  // Tries to access protected page. Check if Email is validatet
  if (user.value.emailVerified) {
    if (to.path.startsWith("/auth")) return navigateTo("/core")
  }
  if (!user.value.emailVerified && to.path !== "/auth/registration-email-validation")
    return navigateTo("/auth/registration-email-validation?sendmail=true", { external: true })
})
