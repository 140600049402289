import { acceptHMRUpdate, defineStore } from "pinia"
import { $t, updatePreset, updateSurfacePalette, usePreset } from "@primevue/themes"
const { presets, primaryColors, surfaces, getPresetExtColor } = useThemeSetting()

export function updateTheme(themeName: string, color: string) {
  return `/themes/${themeName}-${color}/theme.css`
}

export const useThemeStore = defineStore("theme", {
  // states == variables
  state: () => ({
    preset: "Aura" as "Lara" | "Aura",
    primary: "orange",
    surface: "zinc",
    darkTheme: false,
    menuMode: "static",
    scale: 14,

    layoutState: ref({
      staticMenuDesktopInactive: false,
      overlayMenuActive: false,
      profileSidebarVisible: false,
      configSidebarVisible: false,
      staticMenuMobileActive: false,
      menuHoverActive: false,
      activeMenuItem: null as string | null,
    }),
  }),

  // getters == computed
  getters: {
    isSidebarActive: (state) => {
      return state.layoutState.overlayMenuActive || state.layoutState.staticMenuMobileActive
    },
    isDarkTheme: (state) => {
      return state.darkTheme
    },
    getPrimary: (state) => {
      return state.primary
    },
    getSurface: (state) => {
      return state.surface
    },
    getPreset: (state) => {
      const t = primaryColors.value.find((c) => c.name === state.primary) as Color
      return getPresetExtColor(t)
    },
    getSurfacePalette: (state) => {
      return surfaces.value.find((s) => s.name === state.surface)?.palette
    },
  },

  // actions == functions
  actions: {
    setPrimary(colorName: string) {
      this.primary = colorName
      updatePreset(this.getPreset)
    },
    setSurface(colorName: string) {
      this.surface = colorName
      updateSurfacePalette(this.getSurfacePalette)
    },
    setPreset(preset: keyof typeof presets) {
      if (typeof preset === "string") {
        this.preset = preset
      }
      const presetValue = presets[preset]
      const surfacePalette = surfaces.value.find((s) => s.name === this.surface)?.palette
      $t().preset(presetValue).preset(this.getPreset).surfacePalette(surfacePalette).use({ useDefaultOptions: true })
    },
    setActiveMenuItem(value: string) {
      this.layoutState.activeMenuItem = value
    },
    setMenuMode(value: string) {
      this.menuMode = value
    },
    async setDarkMode(v: boolean, transition = true) {
      this.darkTheme = v
      if (transition) {
        if (!document.startViewTransition) {
          if (v) document.documentElement.classList.add("app-dark")
          else document.documentElement.classList.remove("app-dark")
        } else {
          document.startViewTransition(() => {
            if (v) document.documentElement.classList.add("app-dark")
            else document.documentElement.classList.remove("app-dark")
          })
        }
      } else {
        if (v) document.documentElement.classList.add("app-dark")
        else document.documentElement.classList.remove("app-dark")
      }
      await $fetch("/api/core/app-settings/darkMode", {
        method: "POST",
        body: { darkMode: v },
      }).catch((data) => {
        console.log(data)
      })
    },
    toggleDarkMode() {
      if (!document.startViewTransition) {
        this.executeDarkModeToggle()
        return
      }
      this.setDarkMode(!this.darkTheme, true)
      // document.startViewTransition(() => this.executeDarkModeToggle())
    },
    executeDarkModeToggle() {
      this.darkTheme = !this.darkTheme
      document.documentElement.classList.toggle("app-dark")
    },
    onMenuToggle() {
      if (this.menuMode === "overlay") this.layoutState.overlayMenuActive = !this.layoutState.overlayMenuActive

      if (window.innerWidth > 991) this.layoutState.staticMenuDesktopInactive = !this.layoutState.staticMenuDesktopInactive
      else this.layoutState.staticMenuMobileActive = !this.layoutState.staticMenuMobileActive
    },
    resetMenu() {
      this.layoutState.overlayMenuActive = false
      this.layoutState.staticMenuMobileActive = false
      this.layoutState.menuHoverActive = false
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useThemeStore, import.meta.hot))
}
